import { useState, useEffect, useCallback } from 'react';
import axios from 'axios';

function useFetch(url, params = {}, immediate = true) {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const execute = useCallback(
    async () => {
      setLoading(true);
      setData(null);
      setError(null);

      try {
        const response = await axios.get(url, { params });
        if (response.status === 200) {
          setData(response.data);
        } else {
          setError(`Error: ${response.status}`);
        }
      } catch (e) {
        setData(null);
        setError(e);
      } finally {
        setLoading(false);
      }
    },
    [url, params],
  );

  useEffect(() => {
    if (immediate) {
      execute();
    }
  }, [immediate]);

  return {
    data, loading, error, execute,
  };
}
export default useFetch;
