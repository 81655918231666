import React from 'react';
import Navbar from '../../core/web/js/Navbar';
import { AuthenticationContextWrapper } from '../../../providers/AuthenticationProvider';
import '../assets/css/home.css';

function Home() {
  return (
    <AuthenticationContextWrapper>
      <Navbar />
      <div className="mission">
        <div className="greeting">
          <h1>DairyOne Mission</h1>
          <p className="lead">
            The mission of Dairy One is to create and deliver data and
            information which will be used to make profit enhancing decisions
            for members of the agricultural community.
          </p>
        </div>
      </div>

      <div className="values">
        <h1>Core Values</h1>
        <ul>
          <li>
            We have
            {' '}
            <b>Integrity</b>
            . We begin with our own personal integrity
            which we carry over to our collective integrity that is applied to
            all of our work.
          </li>
          <li>
            We are
            {' '}
            <b>Professional</b>
            . We provide accurate, high quality
            services with a respectful, can-do attitude.
          </li>
          <li>
            We are
            {' '}
            <b>Innovative</b>
            . We bring new ideas and services to our
            members to meet their ever changing needs.
          </li>
          <li>
            We are
            {' '}
            <b>Committed</b>
            . We believe in the long term success of our
            members, customers, employees and the industry.
          </li>
          <li>
            We are
            {' '}
            <b>Positive</b>
            . We have a positive outlook on our industry,
            and we bring a positive attitude to our members, customers and
            coworkers.
          </li>
          <li>
            In addition to our core values, there are two beliefs that form the
            foundation of why we do what we do. These beliefs guide us each day.
          </li>
          <li>
            <b>We believe in farming.</b>
          </li>
          <li>
            <b>We make a difference.</b>
          </li>
        </ul>
      </div>
    </AuthenticationContextWrapper>
  );
}

export default Home;
