import React, {
  createContext, useContext, useEffect, useMemo, useState,
} from 'react';
import { PropTypes } from 'prop-types';
import useFetch from '../hooks/useFetch';

export const AuthContext = createContext(null);

export function AuthenticationContextWrapper({ children }) {
  const [authenticated, setAuthenticated] = useState(false);
  const [user, setUser] = useState(null);
  const authState = useMemo(() => ({
    authenticated, user, setAuthenticated, setUser,
  }), [authenticated, user]);

  const { data } = useFetch('/api/v1/user');

  useEffect(() => {
    if (data?.authenticated) {
      setAuthenticated(true);
    }
    if (data?.user) {
      setUser(data.user);
    }
  }, [data]);

  return (
    <AuthContext.Provider value={authState}>
      {children}
    </AuthContext.Provider>
  );
}

export const useAuth = () => {
  const context = useContext(AuthContext);
  return context;
};

AuthenticationContextWrapper.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};
