import React from 'react';
import { createRoot } from 'react-dom/client';
import '../core/web/css/variables.css';
import '../core/web/css/base.css';
import './assets/css/home.css';
import Home from './components/Home';

const container = document.getElementById('homeMain');
const root = createRoot(container);

root.render(<Home />);
